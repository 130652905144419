import { Component, Input, OnChanges, OnInit, Output, EventEmitter, OnDestroy, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import { Profile } from '../../../../models/Profile';
import { Router } from '@angular/router';
import { ProfileService } from '../../../../core/services/profile.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GlobalService } from '../../../../core/services/global.service';
import { QuestionAnswer } from '../../../../models/QuestionAnswer';
import { Question } from '../../../../models/Question';
import { BaseComponent } from '../../base-component/base-component.component';
import { EventProfile } from '../../../../models/EventProfile';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { animations } from 'ack-angular-fx';
import { jelloAnimation, pulseAnimation, tadaAnimation } from 'angular-animations';
import { Event } from '../../../../models/Event';
import { FilterSettings } from '../../../../models/shared/grids/filterSettings';
import { GridSettings } from '../../../../models/shared/grids/gridSettings';

@Component({
  selector: 'invite-list',
  templateUrl: './invite-list.component.html',
  styleUrls: ['./invite-list.component.css'],
  animations: [
    animations,
    pulseAnimation()
  ]
})
export class InviteListComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('inviteList') inviteList: InviteListComponent;
  @ViewChild('search') public searchElementRef: ElementRef;
  @Input() event: Event;


  @Output() invitesChanged = new EventEmitter<EventProfile[]>();

  profiles: Profile[];
  profilesPage: Profile[];
  eventProfiles: EventProfile[] = [];
  gridSettings: GridSettings;
  filterSettings: FilterSettings = new FilterSettings();
  spacesLeft: number = 0;
  total = 0;

  constructor(
    private profileService: ProfileService
  ) {
    super();

    this.isLoading = false;

    this.filterSettings = new FilterSettings();
    this.filterSettings.gender = 0;
    this.filterSettings.distance = 0;
    this.filterSettings.profileId = this.currentProfile.id;


    this.gridSettings = new GridSettings();
    this.gridSettings.pageSize = 12;
    this.gridSettings.pageIndex = 0;
    this.gridSettings.sortField = "FirstName";
    this.gridSettings.sortAsc = true;
  }

  ngOnInit() {
    if (this.event.eventProfile && this.event.eventProfile.length > 0) {
      if (this.event.sizeLimit > 0) {
        this.spacesLeft = this.event.sizeLimit - this.event.eventProfile.filter(x => x.inviteStatus != 4 && x.inviteStatus != 3).length;

      } else {

      }
    }
    this.load();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {


  }

  load() {
    if (this.event.eventProfile) {
      this.eventProfiles = this.event.eventProfile.map(x => x);
      if (this.eventProfiles && this.eventProfiles.length > 0) {
        if (this.event.sizeLimit > 0) {
          this.spacesLeft = this.event.sizeLimit - this.eventProfiles.filter(x => x.inviteStatus != 4 && x.inviteStatus != 3).length;
        } else {

        }
      }
    }
    this.loadSearchResults();

  }

  loadSearchResults() {
    this.profiles = null;
    if (!this.isLoading) {
      this.isLoading = true;
      this.filterSettings.showApproved = true;
      this.profileService.GetForInvitation(this.filterSettings, this.gridSettings.pageIndex, this.gridSettings.pageSize, this.gridSettings.sortField, this.gridSettings.sortAsc ? 1 : -1).subscribe(data => {
        this.total = data.count;
        console.log('loaded for in', data)
        this.profiles = data.result;
        this.setLoading(false);
      });
    }
  }

  onFilterUpdated($event) {
    if ($event != null) {
      this.gridSettings.pageIndex = 0;
      this.filterSettings = $event;
      this.loadSearchResults();
    }
  }



  getImageUrl = (p: Profile): string => { return this.globalService.getProfileImageUrl(p, 256); }

  handleInvite(profileId: number) {
    const newInvite = new EventProfile();
    newInvite.eventId = this.event.id;
    newInvite.profileId = profileId;
    newInvite.created = new Date();
    newInvite.inviteStatus = 2;
    newInvite.profile = this.profiles.find(x => x.id === profileId);
    if (!this.eventProfiles) {
      this.eventProfiles = [];
    }

    const existingInvite = this.eventProfiles.find(x => x.profileId === newInvite.profileId);

    if (existingInvite) {
      this.eventProfiles = this.eventProfiles.filter(x => x.profileId !== newInvite.profileId);
    } else {
      const acceptedInvites = this.eventProfiles.filter(t => t.inviteStatus !== 4 && t.inviteStatus !== 3);
      const hasSpaceAvailable = acceptedInvites.length < this.event.sizeLimit || this.event.sizeLimit === 0;

      if (hasSpaceAvailable) {
        this.eventProfiles.push(newInvite);
      } else {
        console.log(this.eventProfiles);
        console.log(this.event.sizeLimit);
        this.notify("error", "No more invites left. Please increase event size limit.");
      }
    }

    if (this.eventProfiles.length > 0 && this.event.sizeLimit > 0) {
      const acceptedInvites = this.eventProfiles.filter(x => x.inviteStatus !== 4 && x.inviteStatus !== 3);
      this.spacesLeft = this.event.sizeLimit - acceptedInvites.length;
    }
  }



  hasInvite(profileId: number) {
    if (this.eventProfiles != null) {
      return this.eventProfiles.find(x => x.profileId == profileId) != null;
    }
  }



  onPageIndexChange($event) {
    this.gridSettings.pageIndex = $event;
    this.loadSearchResults();
  }

  nzPageSizeChange($event) {
    this.gridSettings.pageSize = $event;
    this.loadSearchResults();
  }

  onEventProfileDeleted($event) {
    this.eventProfiles = this.eventProfiles.filter(x => x.profileId != +$event);
    if (this.eventProfiles && this.eventProfiles.length > 0) {
      if (this.event.sizeLimit > 0) {
        this.spacesLeft = this.event.sizeLimit - this.eventProfiles.filter(x => x.inviteStatus != 4 && x.inviteStatus != 3).length;
      } else {

      }
    }
  }

}
