import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Profile } from '../../../../models/Profile';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ProfileService } from '../../../../core/services/profile.service';
import { QuestionService } from '../../../../core/services/question.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Question } from '../../../../models/Question';
import { QuestionAnswer } from '../../../../models/QuestionAnswer';
import { FilterSettings } from '../../../../models/shared/grids/filterSettings';

@Component({
  selector: 'profile-questions',
  templateUrl: './profile-questions.component.html',
  styleUrls: ['./profile-questions.component.css']
})
export class ProfileQuestionsComponent implements OnInit {
  //@Input() form: FormGroup;
  @Input() pageSize: number;
  @Input() startRecord: number;
  @Input() readonly: boolean = false;
  @Input() step: number;
  @Input() profile: Profile;
  public subscriptions: Subscription[] = [];
  public questions: Question[];
  questionsGroup: UntypedFormGroup;
  filterSettings: FilterSettings;

  @Output() onProfileUpdated: EventEmitter<Profile> = new EventEmitter<Profile>();

  constructor(
    router: Router,

    private questionService: QuestionService,
    private nzMessageService: NzMessageService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef
  ) {
    this.filterSettings = new FilterSettings();
  }

  ngOnInit() {
    // if (!this.profile || !this.profile.questionAnswer) {
    // Wait for profile to be loaded if it's asynchronous
    this.profileService.Get(this.profile.id).subscribe((profileData) => {
      console.log(profileData, 'data..')
      this.profile = profileData;
      this.init();
    });
    // } else {
    //   this.init();
    // }
  }

  private init() {
    this.filterSettings.profileId = this.profile.id;

    // Fetch questions and initialize form
    this.questionService.GetForFilterSettings(this.filterSettings, this.startRecord, this.pageSize, "ViewOrder", 1)
      .subscribe(data => {
        const group: any = {};
        this.questions = data;

        this.questions.forEach(question => {
          let answer = this.profile.questionAnswer.find(x => x.questionId === question.id);

          if (!answer) {
            answer = QuestionAnswer.create(this.profile.id, question.id, "");
            this.profile.questionAnswer.push(answer);
          }
          group[question.id] = new UntypedFormControl(answer.answer || '', Validators.required);
        });

        this.questionsGroup = new UntypedFormGroup(group);
        console.log(this.questions, 'ques..')
      });
  }

  ngOnChanges() {
    this.init()
  }

  submit() {
    if (this.profile.questionAnswer == null) this.profile.questionAnswer = [];

    this.questions.forEach(x => {
      let qa = this.profile.questionAnswer.find(y => y.questionId == x.id);
      if (qa == null) {
        this.profile.questionAnswer.push(QuestionAnswer.create(this.profile.id, x.id, this.questionsGroup.value[x.id]));
      } else {
        qa.answer = this.questionsGroup.value[x.id];
      }
    });


    this.profileService.Update(this.profile).subscribe(data => {
      this.profile = data;
      localStorage.setItem('currentProfile', JSON.stringify(data));

    });
  }

  isValid(id: string) { return this.questionsGroup.controls[id].valid; }

  onChange(id: string) {
    //this.questions.forEach(x => {

    let qa = this.profile.questionAnswer.find(y => y.questionId.toString() == id);

    if (qa == null) {
      this.profile.questionAnswer.push(QuestionAnswer.create(this.profile.id, +id, this.questionsGroup.value[id]));
    } else {
      qa.answer = this.questionsGroup.value[id];
    }

    console.log(qa, 'qa and answ');
    this.onProfileUpdated.next(this.profile);
  }

  log(id: string) {

  }
}
